import dateandtime from "date-and-time";
import { CustomerKey, FareCode, FormatTime } from "./enums";
import vi from "date-and-time/locale/vi";
import { FlightOption, SessionForm } from "src/interfaces/book-flight-ticket-interface";
import domestic from "src/utils/domestic.json";
import { toast } from "react-toastify";

dateandtime.locale(vi);

function convertTime(date, format = FormatTime.DDMMYYYY) {
  const time = new Date(date);
  if (!time) return "";
  return dateandtime.format(time, format);
}

function isEmpty(array_or_object) {
  if (!array_or_object) return true;
  if (array_or_object === void 0) return true;

  if (Array.isArray(array_or_object)) return array_or_object.length === 0;
  if (typeof array_or_object === "object") return Object.keys(array_or_object).length === 0;

  return String(array_or_object).length === 0;
}

function uppercaseString(text: string) {
  if (!text) return "";
  const split = text.split("") || [];
  const firstText = split?.[0].toUpperCase() || "";
  return [firstText, ...split.slice(1)].join("");
}

function convertToCommasFormat(number: string | number): string {
  const strNumber = parseFloat(String(Math.abs(Number(number)))).toString();
  const isNegative = Number(number) < 0;
  const [integerPart] = strNumber.split(".");
  let commaIntegerPart = "";
  for (let i = integerPart.length - 1, j = 0; i >= 0; i--, j++) {
    if (j > 0 && j % 3 === 0) {
      commaIntegerPart = "," + commaIntegerPart;
    }
    commaIntegerPart = integerPart[i] + commaIntegerPart;
  }
  let result = commaIntegerPart;
  return `${isNegative ? "-" : ""}${result}`;
}

function convertNumber(number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "k";
  } else {
    return number.toString();
  }
}

function parseFlightString(routeInfo = "", airlines = "") {
  try {
    const segments = routeInfo.split("-");
    const routesData = segments.slice(0, -3);
    const adult = parseInt(segments[segments.length - 3], 10) || 0;
    const child = parseInt(segments[segments.length - 2], 10) || 0;
    const infant = parseInt(segments[segments.length - 1], 10) || 0;

    const startSegment = routesData?.[0];
    const returnSegment = routesData?.[1];

    const flights = [
      {
        startPoint: startSegment?.slice(0, 3),
        endPoint: startSegment?.slice(3, 6),
        departDate: `${startSegment?.slice(10, 14)}-${startSegment?.slice(8, 10)}-${startSegment?.slice(6, 8)}`,
        returnDate: !!returnSegment
          ? `${returnSegment?.slice(10, 14)}-${returnSegment?.slice(8, 10)}-${returnSegment?.slice(6, 8)}`
          : null,
      },
    ];

    const sources = airlines ? airlines.split(",") : [];

    return {
      adult,
      child,
      infant,
      type: !!returnSegment ? "RT" : "OW",
      currency: "VND",
      sources,
      flights,
      promoCodes: [],
      numberOfStop: 0,
      isGroupedItineraryResponse: false,
    };
  } catch (error) {
    console.log("parseFlightString error >>>", error);
  }
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 *
 * @param inputDate 01-12-2024
 * @returns [28-11-2024, 29-11-2024, 30-11-2024, 01-12-2024, 02-12-2024, 03-12-2024]
 */

function getDateRangeFromInput(inputDate: Date) {
  try {
    const date = dateandtime.format(new Date(inputDate), FormatTime.DDMMYYYY);
    if (!date) return [];
    const baseDate = new Date(date.split("/")?.reverse()?.join("-"));
    const dates = [];

    for (let i = -3; i <= 3; i++) {
      const date = new Date(baseDate);
      date.setDate(baseDate?.getDate() + i);
      dates.push(date?.toISOString()?.split("T")?.[0]);
    }

    return dates;
  } catch (error) {
    console.log("error >>>", error);
  }
}

/**
 *
 * @param startTime 10:00
 * @param endTime 12:00
 * @returns 02:00
 */

function calculateTimeDifference(startTime: string, endTime: string) {
  try {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    let startTotalMinutes = startHour * 60 + startMinute;
    let endTotalMinutes = endHour * 60 + endMinute;

    if (endTotalMinutes < startTotalMinutes) {
      endTotalMinutes += 24 * 60;
    }

    const diffMinutes = endTotalMinutes - startTotalMinutes;

    const diffHours = Math.floor(diffMinutes / 60);
    const remainingMinutes = diffMinutes % 60;

    return `${diffHours.toString().padStart(2, "0")}:${remainingMinutes.toString().padStart(2, "0")}`;
  } catch (error) {
    console.log("Error", error);
  }
}

/**
 *
 * @param flightString HANSGN30112024-SGNHAN29122024-1-0-0
 * @param type departure
 * @param newDate 05122024
 * @returns  HANSGN05122024-SGNHAN29122024-1-0-0
 */

function updateFlightDateWithExtras(flightString, type, newDate) {
  try {
    const [departureSegment, arrivalSegment, ...extras] = flightString.split("-");

    if (type === "departure") {
      const newDepartureSegment = departureSegment?.slice(0, -8) + newDate;
      return [newDepartureSegment, arrivalSegment, ...extras].join("-");
    } else if (type === "arrival") {
      const newArrivalSegment = arrivalSegment?.slice(0, -8) + newDate;
      return [departureSegment, newArrivalSegment, ...extras].join("-");
    } else {
      throw new Error("Invalid type. Please use 'departure' or 'arrival'.");
    }
  } catch (error) {
    console.log("Error", error);
  }
}

function findFareOption(fareOptions) {
  try {
    // Xử lý fareBreakdowns
    if (isEmpty(fareOptions)) return {};
    let dataFareOption = fareOptions?.[0];
    fareOptions?.forEach((val) => {
      if (val?.[FareCode.total] < dataFareOption?.[FareCode.total]) {
        dataFareOption = val;
      }
    });
    return dataFareOption;
  } catch (error) {
    console.log("Error", error);
  }
}

function generateFareOptionId(options: any = {}, buffer, offset) {
  const supportsRandomUUID = typeof crypto !== "undefined" && typeof crypto.randomUUID === "function";

  if (supportsRandomUUID && !buffer && !options) {
    return crypto.randomUUID();
  }

  function getRandomValues() {
    if (!crypto || !crypto.getRandomValues) {
      throw new Error("crypto.getRandomValues() is not supported.");
    }
    return crypto.getRandomValues(new Uint8Array(16));
  }

  const randomValues = (options?.random || getRandomValues)();

  randomValues[6] = (randomValues[6] & 0x0f) | 0x40;
  randomValues[8] = (randomValues[8] & 0x3f) | 0x80;

  if (buffer) {
    offset = offset || 0;
    for (let i = 0; i < 16; ++i) {
      buffer[offset + i] = randomValues[i];
    }
    return buffer;
  }

  return byteArrayToUUIDString(randomValues);
}

function byteArrayToUUIDString(bytes) {
  try {
    const hexTable = [];
    for (let i = 0; i < 256; ++i) {
      hexTable.push((i + 256).toString(16).slice(1));
    }

    return (
      hexTable[bytes[0]] +
      hexTable[bytes[1]] +
      hexTable[bytes[2]] +
      hexTable[bytes[3]] +
      "-" +
      hexTable[bytes[4]] +
      hexTable[bytes[5]] +
      "-" +
      hexTable[bytes[6]] +
      hexTable[bytes[7]] +
      "-" +
      hexTable[bytes[8]] +
      hexTable[bytes[9]] +
      "-" +
      hexTable[bytes[10]] +
      hexTable[bytes[11]] +
      hexTable[bytes[12]] +
      hexTable[bytes[13]] +
      hexTable[bytes[14]] +
      hexTable[bytes[15]]
    );
  } catch (error) {
    console.log("byteArrayToUUIDString error", error);
  }
}

function convertItinerariesData(input: FlightOption, session: SessionForm, paxList, bagData) {
  try {
    const { fareOptions, segments, departure } = input;

    const { adult = 0, child = 0, infant = 0, flights } = session;
    const isRT = departure?.IATACode === flights?.[0]?.endPoint;

    let fareData = [];
    adult > 0 && fareData.push(CustomerKey.adult.toUpperCase());
    child > 0 && fareData.push(CustomerKey.child.toUpperCase());
    infant > 0 && fareData.push(CustomerKey.infant.toUpperCase());

    // Xử lý fareBreakdowns
    const dataFareOption = findFareOption(fareOptions);

    const fareBreakdowns = fareData?.map((item) => {
      return {
        paxType: item,
        netFare: dataFareOption?.fareAdult || 0,
        discountAmount: 0,
        discountAmountParent: 0,
        // tax: dataFareOption?.taxAdult || 0,
        tax: dataFareOption?.surchargeAdult + dataFareOption?.taxAdult,
        total: dataFareOption?.totalAdult || 0,
        fareValue: dataFareOption?.fareValue || "",
      };
    });

    // Xử lý segments
    const processedSegments = segments?.map((segment) => ({
      leg: segment?.leg || 0,
      airCraft: segment?.airCraft || "",
      airline: segment?.airline || "",
      arrival: segment?.arrival?.IATACode || "",
      arrivalTime:
        segment?.arrival?.at?.replace("Z", segment?.arrival?.timezone).replace(" ", "T").replace(".000", "") || "",
      departure: segment?.departure?.IATACode || "",
      departureTime:
        segment?.departure?.at?.replace("Z", segment?.departure?.timezone).replace(" ", "T").replace(".000", "") || "",
      duration: segment?.duration || 0,
      flightNumber: segment?.flightNumber || "",
      fareType: dataFareOption?.fareType || "",
      fareBasisCode: dataFareOption?.fareBasisCode || "",
      fareOptionId: generateFareOptionId({}, "", "") || "",
      bookingClass: dataFareOption?.bookingClass || "",
      groupClass: dataFareOption?.groupClass || "",
      bookingClassId: segment?.bookingClassId || "",
      marriageGrp: segment?.marriageGrp || "",
      operating: segment?.operating || "",
      segmentId: segment?.segmentId || "",
      segmentValue: segment?.segmentValue || "",
      departureTzOffset: segment?.departure?.timezone || "",
      arrivalTzOffset: segment?.arrival?.timezone || "",
    }));

    //Xử lý hành lý
    let paxssr = [];
    paxList?.forEach((val) => {
      const bagCode = isRT ? val?.ancillaryCode?.rt : val?.ancillaryCode?.ow;
      if (!bagCode) return;
      const bagValue = bagData?.[isRT ? "rt" : "ow"];
      const findValue = bagValue?.find((ite) => ite?.code === bagCode) || {};
      paxssr.push({
        code: findValue?.code || "",
        type: "",
        name: "",
        details: findValue?.details || [],
        paxId: val?.paxId || "",
        quantity: 1,
        price: findValue?.fares?.find((value) => value?.paxType === val?.paxType)?.total || 0,
        itineraryId: processedSegments?.[0]?.segmentValue || "",
        segmentId: findValue?.segmentIds?.[0] || "",
        addonValue: findValue?.ssrValue || "",
      });
    });

    const result = {
      domestic: input.domestic,
      source: input.source || "",
      airline: input.airline || "",
      bookingKey: "string",
      clientId: input.clientId || "",
      fareBreakdowns,
      paxSeat: [],
      itineraryId: processedSegments?.[0]?.segmentValue || "",
      paxssr: isEmpty(paxssr) ? [] : paxssr,
      segments: processedSegments,
    };

    return result;
  } catch (error) {
    console.log("Error", error);
  }
}

function filterByRangeTime(data: FlightOption[], departureStart, departureEnd, arrivalStart, arrivalEnd) {
  let dataFilter = [];
  data?.forEach((val) => {
    const departureTime = new Date(val?.departure?.at).getHours();
    const arrivalTime = new Date(val?.arrival?.at).getHours();
    if (
      departureStart <= departureTime &&
      departureTime <= departureEnd &&
      arrivalStart <= arrivalTime &&
      arrivalTime <= arrivalEnd
    )
      return dataFilter.push(val);
  });

  return dataFilter;
}

function convertTextFlight(startPoint, endPoint, isShowCode = true) {
  if (!startPoint && !endPoint) return "";
  const start = domestic?.find((val) => val?.code === startPoint);
  const end = domestic?.find((val) => val?.code === endPoint);
  if (isShowCode) return `${start?.city || ""} (${start?.code || ""}) ➞ ${end?.city || ""} (${end?.code || ""})`;
  return `${start?.city || ""} ➞ ${end?.city || ""}`;
}

async function copyTClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Sao chép thành công");
  } catch (err) {
    toast.error("Sao chép thất bại");
    throw err;
  }
}

export {
  convertTime,
  uppercaseString,
  parseFlightString,
  getDateRangeFromInput,
  isEmpty,
  convertToCommasFormat,
  convertNumber,
  filterByRangeTime,
  calculateTimeDifference,
  updateFlightDateWithExtras,
  sleep,
  convertItinerariesData,
  convertTextFlight,
  findFareOption,
  copyTClipboard,
  generateFareOptionId,
};

import { useEffect } from "react";
import Loading from "../loading/Loading";
import "./loading-full.scss";

const LoadingFullScreen = ({ show = false }: { show: boolean }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Cleanup khi component unmount
    return () => {
      document.body.style.overflow = "";
    };
  }, [show]);

  return (
    <div className='loading-screen'>
      <Loading />
    </div>
  );
};

export default LoadingFullScreen;

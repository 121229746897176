import { useEffect } from "react";

const useScrollToTop = (dependency = []) => {
  useEffect(() => {
    typeof window !== "undefined" &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependency);
};

export default useScrollToTop;

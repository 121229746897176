import React, { useEffect } from "react";
import Iconify from "src/components/specific/iconify";
import Typography from "src/components/common/typography";
import { useForm, UseFormRegister, UseFormWatch, UseFormSetValue } from "react-hook-form";
import { TitleKey } from "src/utils/enums";
import { isEmpty } from "src/utils/helps";

import "../styles/form-contact.scss";

/** function get and write localStorage */

interface Props {
  registerForm: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  errors: any;
}

const FormContact = ({ watch, errors, registerForm, setValue }: Props) => {
  const values = watch();

  useEffect(() => {
    const contactInfo = JSON.parse(localStorage.getItem("paxContactInfo") || "{}");
    if (!isEmpty(contactInfo)) {
      setValue("paxContactInfo.gender", contactInfo.gender);
      setValue("paxContactInfo.fullName", contactInfo.fullName);
      setValue("paxContactInfo.phoneNumber", contactInfo.phoneNumber);
      setValue("paxContactInfo.email", contactInfo.email);
      setValue("paxContactInfo.address", contactInfo.address);
    }
  }, [setValue]);

  return (
    <div className='contact-info'>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          background: "var(--light-2)",
          padding: "8px 10px",
          borderLeft: "5px solid var(--green)",
        }}
      >
        <Iconify icon='fluent:contact-card-48-filled' />
        <Typography variant='body1'>Thông tin liên hệ</Typography>
      </div>

      <div className='form-wrapper'>
        <div className='form-group'>
          <div className='form-field'>
            <label>Quý danh</label>
            <select required {...registerForm("paxContactInfo.gender")}>
              <option value={TitleKey.MR}>Ông</option>
              <option value={TitleKey.MRS}>Bà</option>
            </select>
          </div>
          <div className='form-field'>
            <div style={{ display: "flex" }}>
              <label>Họ tên</label>
              <Typography style={{ color: "var(--danger)" }}>*</Typography>
            </div>
            <input
              type='text'
              required
              {...registerForm("paxContactInfo.fullName")}
            />
          </div>
          <div className='form-field'>
            <label>Số điện thoại</label>
            <input
              type='text'
              required
              {...registerForm("paxContactInfo.phoneNumber", {
                required: true,
                pattern: {
                  value: /^[0-9]{10,11}$/,
                  message: "Số điện thoại sai định dạng!",
                },
              })}
            />
            {!isEmpty(errors?.paxContactInfo?.phoneNumber) && (
              <Typography variant='caption' style={{ color: "red" }}>
                {errors?.paxContactInfo?.phoneNumber?.message || ""}
              </Typography>
            )}
          </div>
          <div className='form-field'>
            <div style={{ display: "flex" }}>
              <label>Email</label>
              <Typography style={{ color: "var(--danger)" }}>*</Typography>
            </div>
            <input
              type='text'
              required
              placeholder='Email'
              {...registerForm("paxContactInfo.email", {
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Email sai định dạng!",
                },
              })}
            />
            {!isEmpty(errors?.paxContactInfo?.email) && (
              <Typography variant='caption' style={{ color: "red" }}>
                {errors?.paxContactInfo?.email?.message || ""}
              </Typography>
            )}
          </div>
          <div className='form-field'>
            <label>Địa chỉ</label>
            <input type='text' placeholder='Địa chỉ' {...registerForm("paxContactInfo.address")} />
          </div>
          <div className='form-field'>
            <label>Ghi chú</label>
            <input type='text' placeholder='Yêu cầu đặc biệt' {...registerForm("paxContactInfo.note")} />
          </div>
        </div>
      </div>
    </div>
  );
};


export default FormContact;
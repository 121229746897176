import FormContact from "./form-contact";
import FormInfo from "./form-info";
import FormPayment from "./form-payment";
import { SessionForm } from "src/interfaces/book-flight-ticket-interface";
import { useFlightContext } from "src/context/flight-context";
import LoadingFullScreen from "src/components/specific/loading-full";

export default function FormView({
  handleResetSelectFlight,
  sessionData,
  handleBookingFlight,
  isLoading,
}: {
  handleResetSelectFlight: Function;
  sessionData: SessionForm;
  handleBookingFlight: (data) => void;
  isLoading: boolean;
}) {
  const { bagOptions, register, errors, setValue, watch, handleSubmit } = useFlightContext();


  const onSubmit = (data, event) => {
    event.preventDefault();
    handleBookingFlight(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInfo
        registerForm={register}
        bagOptions={bagOptions}
        errors={errors}
        setValue={setValue}
        sessionData={sessionData}
      />

      <FormContact watch={watch} errors={errors} registerForm={register} setValue={setValue} />

      <FormPayment setValue={setValue} handleResetSelectFlight={handleResetSelectFlight} />

      {isLoading && <LoadingFullScreen show={isLoading} />}
    </form>
  );
}

import Iconify from "src/components/specific/iconify";
import Typography from "src/components/common/typography";
import { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { PaymentMethodKey } from "src/utils/enums";

import "../styles/form-payment.scss";

const FormPayment = ({
  setValue,
  handleResetSelectFlight,
}: {
  handleResetSelectFlight: Function;
  setValue: UseFormSetValue<any>;
}) => {
  const [selectedOption, setSelectedOption] = useState(PaymentMethodKey.QR);

  useEffect(() => {
    setValue("paymentType", selectedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <div className='payment-info'>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          background: "var(--light-2)",
          padding: "8px 10px",
          borderLeft: "5px solid var(--green)",
        }}
      >
        <Iconify icon='fluent:payment-16-filled' />
        <Typography variant='body1'>Phương thức thanh toán</Typography>
      </div>

      <div className='payment-options'>
        <div
          className={`option ${selectedOption === PaymentMethodKey.QR ? "active" : ""}`}
          onClick={() => setSelectedOption(PaymentMethodKey.QR)}
        >
          <div>
            <input
              type='radio'
              id='bankTransfer'
              name='payment'
              checked={selectedOption === PaymentMethodKey.QR}
              onChange={() => setSelectedOption(PaymentMethodKey.QR)}
            />
            <label htmlFor='bankTransfer'>Thanh toán chuyển khoản qua hệ thống ngân hàng</label>
          </div>
          {selectedOption === PaymentMethodKey.QR && (
            <div className='details'>
              <Typography variant='subtitle2'>Ngân hàng: VIETCOMBANK</Typography>
              <Typography variant='subtitle2'>Tên tài khoản: PHẠM XUÂN TUẤN</Typography>
              <Typography variant='subtitle2'>Số tài khoản: 0341006873081</Typography>
            </div>
          )}
        </div>

        <div
          className={`option ${selectedOption === PaymentMethodKey.OFFICE ? "active" : ""}`}
          onClick={() => setSelectedOption(PaymentMethodKey.OFFICE)}
        >
          <div>
            <input
              type='radio'
              id='officePayment'
              name='payment'
              checked={selectedOption === PaymentMethodKey.OFFICE}
              onChange={() => setSelectedOption(PaymentMethodKey.OFFICE)}
            />
            <label htmlFor='officePayment'>Thanh toán tại văn phòng</label>
          </div>

          {selectedOption === PaymentMethodKey.OFFICE && (
            <div className='details'>
              <Typography variant='subtitle2'>Địa chỉ: 38 Bùi Thị Xuân, TP Hải Dương (Đối diện nhà thi đấu)</Typography>
              <Typography variant='subtitle2'>Hotline: 0979680680</Typography>
            </div>
          )}
        </div>

        <div className='buttons'>
          <button
            type='button'
            className='back-btn'
            onClick={(e) => {
              e.preventDefault();
              handleResetSelectFlight(e);
            }}
          >
            <Iconify icon='weui:back-filled' />
            <Typography variant='button'>Quay lại</Typography>
          </button>
          <button className='confirm-btn' type='submit'>
            <Typography variant='button'>Đặt chỗ</Typography>
            <Iconify icon='weui:back-filled' style={{ transform: "rotate(180deg)" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormPayment;

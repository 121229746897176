import { useCallback, useEffect, useMemo } from "react";
import Typography from "src/components/common/typography";
import {
  Airline,
  AirlineImage,
  AirlineName,
  BagValue,
  Customer,
  FormatTime,
  PaymentMethod,
  PaymentMethodKey,
  SeatClass,
} from "src/utils/enums";
import { calculateTimeDifference, convertTime, convertToCommasFormat, copyTClipboard, isEmpty } from "src/utils/helps";
import domestic from "src/utils/domestic.json";
import condition from "src/utils/condition.json";
import { FlightOption } from "src/interfaces/book-flight-ticket-interface";
import { BOOKING_KEY, INFO_PAYMENT } from "src/utils/constants";
import { getStorage, removeStorage, setStorage } from "src/hooks/use-local-storage";
import Iconify from "src/components/specific/iconify";
import axiosClient from "src/apis/axiosClient";
import { toast } from "react-toastify";
import useScrollToTop from "src/hooks/use-scroll-top";

import "../styles/overview-booking.scss";

export default function OverviewBooking() {
  const dataBooking = getStorage(BOOKING_KEY);
  const dataInfoPayment = getStorage(INFO_PAYMENT);
  const isNotify = getStorage("is_notify");

  const flightData: any = useMemo(() => {
    return dataBooking?.itineraries?.map((val) => val?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBooking?.itineraries]);

  const conditionFlight = useCallback((flightData: FlightOption) => {
    return condition?.[flightData?.airline]?.[flightData?.bookingClass]?.condition;
  }, []);

  const handleNotifyBooking = useCallback(async () => {
    await axiosClient
      .post(`/order/${dataBooking?.orderId}/notify-booking`)
      .then(() => {
        toast.success("Thông báo thành công");
        setStorage("is_notify", true);
      })
      .catch((error) => {
        console.log("handleNotifyBooking error", error);
      });
  }, [dataBooking]);

  useEffect(() => {
    //Cleanup function
    return () => {
      removeStorage(BOOKING_KEY);
      removeStorage(INFO_PAYMENT);
      removeStorage("is_notify");
    };
  });

  useScrollToTop();

  if (isEmpty(dataBooking)) return <></>;
  return (
    <div id='OverviewBooking' className='overview-booking__container'>
      {/* order code */}
      <div className='overview-booking__info'>
        <div className='overview-booking__info-head'>
          <Typography variant='body1'>Mã đặt chỗ: {dataBooking?.bookingCode || ""}</Typography>
        </div>
        <div className='overview-booking__info-content'>
          <Typography variant='subtitle1' style={{ color: "var(--green)" }}>
            Mã đặt chỗ: {dataBooking?.bookingCode || ""} - thanh toán trước{" "}
            {convertTime(
              new Date(dataBooking?.updatedAt).setMinutes(new Date(dataBooking?.updatedAt).getMinutes() + 30),
              FormatTime.HHMM
            )}{" "}
            ngày{" "}
            {convertTime(
              new Date(dataBooking?.updatedAt).setMinutes(new Date(dataBooking?.updatedAt).getMinutes() + 30),
              FormatTime.ddmmyyyy
            )}
          </Typography>
        </div>
      </div>

      {/* Flight detail */}
      {!isEmpty(flightData) && (
        <div className='overview-booking__info'>
          <div className='overview-booking__info-head'>
            <Typography variant='body1'>Chi tiết chuyến bay</Typography>
          </div>
          {!isEmpty(flightData) &&
            flightData?.map((val: FlightOption, idx: number) => (
              <div key={idx} style={{ width: "100%", display: "grid" }}>
                <Typography variant='h6' className='flight-title'>
                  Chuyến bay: {val?.departure?.city || ""} ➞ {val?.arrival?.city || ""}
                </Typography>
                <div>
                  <div className='details-box'>
                    <ul className='flight-info'>
                      <li className='flight-info-item'>
                        <img src={AirlineImage?.[val?.airline]} alt={val?.airline} />
                        <Typography>{AirlineName?.[val?.airline]}</Typography>
                      </li>
                      <li>
                        <Typography>{`${val?.departure?.city} (${val?.departure?.iataCode})`}</Typography>
                        <Typography>
                          {domestic?.find((item) => item?.code === val?.departure?.iataCode)?.name || ""}
                        </Typography>
                        <Typography>{`Cất cánh: ${convertTime(val?.departure?.at, FormatTime.HHMM)}`}</Typography>
                        <Typography>{`Ngày: ${convertTime(val?.departure?.at, FormatTime.ddmmyyyy)}`}</Typography>
                      </li>
                      <li>
                        <Typography>{`${val?.arrival?.city} (${val?.arrival?.iataCode})`}</Typography>
                        <Typography>
                          {domestic?.find((item) => item?.code === val?.arrival?.iataCode)?.name || ""}
                        </Typography>
                        <Typography>{`Hạ cánh: ${convertTime(val?.arrival?.at, FormatTime.HHMM)}`}</Typography>
                        <Typography>{`Ngày: ${convertTime(val?.arrival?.at, FormatTime.ddmmyyyy)}`}</Typography>
                      </li>
                      <li>
                        <Typography> {`Chuyến bay: ${val?.airline}${val?.flightNumber}`}</Typography>
                        <Typography>{`Thời gian bay: ${calculateTimeDifference(convertTime(new Date(val?.departure?.at), FormatTime.HHMM), convertTime(new Date(val?.arrival?.at), FormatTime.HHMM))}`}</Typography>
                        <Typography>{`Hạng chỗ: ${SeatClass?.[val?.airline]}`}</Typography>
                        <Typography>{`Máy bay: ${Airline?.[val?.airline] || ""}`}</Typography>
                        <Typography>Hành lý xách tay: {conditionFlight(val)?.["Hành lý xách tay"] || ""}</Typography>
                        <Typography>Hành lý ký gửi: {conditionFlight(val)?.["Hành lý ký gửi"] || ""}</Typography>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}

      {/* List user */}
      {!isEmpty(dataBooking?.paxLists) && (
        <div className='overview-booking__info'>
          <div className='overview-booking__info-head black'>
            <Typography variant='body1'>Thông tin hành khách và giá vé</Typography>
          </div>

          {!isEmpty(dataBooking?.ancillaryServices) && (
            <div style={{ width: "100%", display: "grid" }}>
              <Typography variant='h6' className='flight-title'>
                Thông tin hành khách
              </Typography>

              <div className='table-head'>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th>
                        <Typography>STT</Typography>
                      </th>
                      <th>
                        <Typography>Hành khách</Typography>
                      </th>
                      <th>
                        <Typography>Dịch vụ</Typography>
                      </th>
                      <th>
                        <Typography>Chuyến bay</Typography>
                      </th>
                      <th>
                        <Typography>Tổng tiền</Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(dataBooking?.ancillaryServices) &&
                      dataBooking?.ancillaryServices.map((item, index) => {
                        let flight: any = {};
                        const userData: any = dataBooking?.paxLists?.find((val) => val?.paxId === item?.paxId) || {};
                        dataBooking?.itineraries?.forEach((val) => {
                          val?.forEach((ite) => {
                            if (ite?.segmentId === item?.segmentIds?.[0]) return (flight = ite);
                          });
                        });

                        return (
                          <tr key={index}>
                            <td>
                              <Typography variant='caption'>{index + 1}</Typography>
                            </td>
                            <td>
                              <Typography variant='caption'>{`${userData?.lastName || ""} ${userData?.firstName || ""}`}</Typography>
                            </td>
                            <td>
                              <Typography variant='caption'>{BagValue?.[item?.serviceSubCode]}</Typography>
                            </td>
                            <td>
                              <Typography variant='caption'>{`${flight?.departure?.iataCode || ""}-${flight?.arrival?.iataCode || ""}`}</Typography>
                            </td>
                            <td>
                              <Typography variant='caption'>{convertToCommasFormat(item?.totalPrice || 0)}</Typography>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {!isEmpty(dataBooking?.newPriceFormatted) &&
            dataBooking?.newPriceFormatted?.map((val, idx) => (
              <div key={idx} style={{ width: "100%", display: "grid" }}>
                <Typography variant='h6' className='flight-title'>
                  Chi tiết giá vé {val?.airports || ""}
                </Typography>

                <div className='table-head'>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th>
                          <Typography>Hành khách</Typography>
                        </th>
                        <th>
                          <Typography>Giá vé</Typography>
                        </th>
                        <th>
                          <Typography>Thuế và phí</Typography>
                        </th>
                        <th>
                          <Typography>Tổng tiền</Typography>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(val?.items) &&
                        val?.items.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Typography variant='caption'>
                                {Customer?.[item?.paxType?.toLowerCase()] || ""} x {item?.amountPassenger || 1}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant='caption'>{convertToCommasFormat(item?.netPrice || 0)}</Typography>
                            </td>
                            <td>
                              <Typography variant='caption'>{convertToCommasFormat(item?.tax || 0)}</Typography>
                            </td>
                            <td>
                              <Typography variant='caption'>{convertToCommasFormat(item?.total || 0)}</Typography>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div style={{ textAlign: "right", marginTop: "16px", fontSize: "16px" }}>
                    <strong>Tổng: {convertToCommasFormat(val?.sum || 0)} VND</strong>
                  </div>
                </div>
              </div>
            ))}

          <div style={{ textAlign: "right", fontSize: "16px", padding: "10px" }}>
            <strong style={{ color: "#FF6600" }}>
              Tổng chi phí (VND): {convertToCommasFormat(dataBooking?.newSumAll || 0)} VND
            </strong>
          </div>
        </div>
      )}

      {/* Contact */}
      {!isEmpty(dataBooking?.orderPaxContactInfo) && (
        <div className='overview-booking__info'>
          <div className='overview-booking__info-head black'>
            <Typography variant='body1'>Thông tin liên hệ</Typography>
          </div>
          <div style={{ width: "100%", display: "grid" }}>
            <ul className='contact-info__view'>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Họ tên:{" "}
                </Typography>
                <Typography>{dataBooking?.orderPaxContactInfo?.fullName || ""}</Typography>
              </li>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Email:{" "}
                </Typography>
                <Typography>{dataBooking?.orderPaxContactInfo?.email || ""}</Typography>
              </li>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Điện thoại:{" "}
                </Typography>
                <Typography>{dataBooking?.orderPaxContactInfo?.phoneNumber || ""}</Typography>
              </li>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Phương thức thanh toán:{" "}
                </Typography>
                <Typography>{PaymentMethod?.[dataBooking?.paymentType] || ""}</Typography>
              </li>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Yêu cầu đặc biệt:{" "}
                </Typography>
                <Typography>{dataBooking?.orderPaxContactInfo?.note || ""}</Typography>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Info export invoice */}
      {!isEmpty(dataBooking?.bookingInvoice) && (
        <div className='overview-booking__info'>
          <div className='overview-booking__info-head black'>
            <Typography variant='body1'>Thông tin xuất hóa đơn</Typography>
          </div>
          <div style={{ width: "100%", display: "grid" }}>
            <ul className='contact-info__view'>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Tên công ty:{" "}
                </Typography>
                <Typography>{dataBooking?.bookingInvoice?.companyName || ""}</Typography>
              </li>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Địa chỉ:{" "}
                </Typography>
                <Typography>{dataBooking?.bookingInvoice?.address || ""}</Typography>
              </li>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Thành phố:{" "}
                </Typography>
                <Typography>{dataBooking?.bookingInvoice?.cityName || ""}</Typography>
              </li>
              {/* <li>
              <Typography variant='body1' style={{ fontWeight: 500 }}>
                Mã bưu điện:{" "}
              </Typography>
              <Typography>{dataBooking?.bookingInvoice?.companyName || ''}</Typography>
            </li> */}
              {/* <li>
              <Typography variant='body1' style={{ fontWeight: 500 }}>
                Quốc gia:{" "}
              </Typography>
              <Typography>{dataBooking?.bookingInvoice?.companyName || ''}</Typography>
            </li> */}
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Mã số thuế VAT:{" "}
                </Typography>
                <Typography>{dataBooking?.bookingInvoice?.taxCode || ""}</Typography>
              </li>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Người nhận hóa đơn:{" "}
                </Typography>
                <Typography>{dataBooking?.bookingInvoice?.receiver || ""}</Typography>
              </li>
              <li>
                <Typography variant='body1' style={{ fontWeight: 500 }}>
                  Email:{" "}
                </Typography>
                <Typography>{dataBooking?.bookingInvoice?.email || ""}</Typography>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Info payment */}
      {!isEmpty(dataInfoPayment) && dataBooking?.paymentType === PaymentMethodKey.QR ? (
        <div className='overview-booking__info'>
          <div className='overview-booking__info-head black'>
            <Typography variant='body1'>Thông tin thanh toán</Typography>
          </div>
          <div className='table-head'>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead></thead>
              <tbody>
                <tr>
                  <td>
                    <Typography variant='caption'>Chủ tài khoản</Typography>
                  </td>
                  <td>
                    <Typography variant='caption'>{dataInfoPayment?.bankAccountName || ""}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant='caption'>Số tài khoản</Typography>
                  </td>
                  <td style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Typography variant='caption'>{dataInfoPayment?.bankAccountNo || ""}</Typography>
                    <div
                      style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "4px" }}
                      onClick={() => copyTClipboard(dataInfoPayment?.bankAccountNo || "")}
                    >
                      <Iconify icon='meteor-icons:copy' />
                      <Typography variant='caption'>Sao chép</Typography>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant='caption'>Thanh toán mã đơn hàng</Typography>
                  </td>
                  <td style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Typography variant='caption'>{dataInfoPayment?.paymentCode || ""}</Typography>
                    <div
                      style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "4px" }}
                      onClick={() => copyTClipboard(dataInfoPayment?.paymentCode || "")}
                    >
                      <Iconify icon='meteor-icons:copy' />
                      <Typography variant='caption'>Sao chép</Typography>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant='caption'>Số tiền cần chuyển</Typography>
                  </td>
                  <td>
                    <Typography style={{ color: "var(--orange)" }}>
                      {convertToCommasFormat(dataInfoPayment?.paymentAmount || 0)}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              style={{
                display: "grid",
                justifyContent: "center",
                justifyItems: "center",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Typography variant='caption' style={{ textAlign: "center" }}>
                Hoặc sử dụng mã QR để thanh toán{" "}
              </Typography>
              <img src={dataInfoPayment?.qrCodeUrl || ""} alt='image/payment' />

              <Typography style={{ color: "var(--danger)" }}>
                Quý Khách vui lòng đợi ít phút sau khi chuyển tiền để hệ thống có thể xác nhận
              </Typography>
              {!isNotify && (
                <Typography style={{ fontWeight: 600 }}>Nhấn vào nút dưới đây để thông báo cho đại lý</Typography>
              )}

              <div style={{ paddingBottom: "10px" }}>
                <button className='button' onClick={() => (isNotify ? null : handleNotifyBooking())}>
                  <Iconify icon='icon-park-outline:success' />
                  Đã thanh toán
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

import Typography from "src/components/common/typography";
import Iconify from "src/components/specific/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import FlightDetail from "./flight-detail";
import { AirlineImage, FareCode, FormatTime } from "src/utils/enums";
import { convertTime, convertToCommasFormat } from "src/utils/helps";
import { useMemo } from "react";
import { FilterOption, FlightOption } from "src/interfaces/book-flight-ticket-interface";

interface Props {
  filter?: FilterOption;
  flight?: FlightOption;
  handleSelectFlight?: (value) => void;
}

export default function FlightListItem({ flight, filter, handleSelectFlight }: Props) {
  const showDetail = useBoolean();

  const price = useMemo(() => {
    let data = flight?.fareOptions?.[0];
    flight?.fareOptions?.forEach((val) => {
      if (val?.[FareCode.total] < data?.[FareCode.total]) {
        data = val;
      }
    });
    return data;
  }, [flight]);

  return (
    <>
      <ul className='table-body-ul'>
        <li className={`table-body-item`}>
          <img src={AirlineImage?.[flight?.airline]} alt={flight?.airline} />
        </li>
        <li className={`table-body-item`}>
          <Typography variant='subtitle1'>{convertTime(flight?.departure?.at, FormatTime.HHMM)}</Typography>
        </li>
        <li className={`table-body-item`}>
          <div style={{ display: "flex", alignItems: "center", gap: "4px", color: "var(--green)" }}>
            <Iconify icon='fa:plane' style={{ width: 10, height: 9 }} />
            <Typography
              style={{ fontWeight: 500 }}
              variant='caption'
            >{`${flight?.airline}${flight?.flightNumber}`}</Typography>
          </div>
          <Typography
            onClick={showDetail.onToggle}
            variant='caption'
            style={{ color: "var(--orange)", cursor: "pointer" }}
          >
            [{showDetail.value ? "Ẩn" : "Chi tiết"}]
          </Typography>
        </li>
        <li className={`table-body-item`}>
          <Typography variant='subtitle1'>{convertTime(flight?.arrival?.at, FormatTime.HHMM)}</Typography>
        </li>
        <li className={`table-body-item-mob`}>
          <Typography style={{ fontWeight: 500 }}>{convertTime(flight?.departure?.at, FormatTime.HHMM)}</Typography>
          <Iconify icon='fa:plane' style={{ transform: "rotate(45deg)", width: 10, height: 9 }} />
          <Typography style={{ fontWeight: 500 }}>{convertTime(flight?.arrival?.at, FormatTime.HHMM)}</Typography>
        </li>
        <li className={`table-body-item`}>
          <Typography
            style={{ color: "var(--orange)", fontWeight: 600 }}
          >{price ? convertToCommasFormat(price?.[filter?.displayMode] || 0) : "Hết chỗ"}</Typography>
        </li>
        <li className={`table-body-item`}>
          <button disabled={!price ? true : false} className='select-btn' onClick={() => handleSelectFlight(flight)}>
            <Typography variant='button'>Chọn</Typography>
            <Iconify icon='ic:sharp-navigate-next' />
          </button>
        </li>
      </ul>

      {showDetail.value && <FlightDetail flight={flight} />}
    </>
  );
}
